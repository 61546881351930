import React from 'react';

import Container from '@material-ui/core/Container';

import { makeStyles } from "@material-ui/core/styles"
import { useStaticQuery, graphql } from "gatsby"
import Box from "@material-ui/core/Box"

import Hero from "../../components/hero"
import RobertoView from "./RobertoView"
import GreyTextView from "./GreyTextView"

const useStyles = makeStyles(theme => ({

	heroTitle: {
		'& section > h1': {
			[theme.breakpoints.down('lg')]: {
				fontSize: '140px',
			},
			'@media(max-width: 768px)': {
				fontSize: '110px ',
			},
			[theme.breakpoints.down('xs')]: {
				fontSize: '80px ',
			},
			'@media(max-width: 480px)': {
				fontSize: '50px ',
			},
		},
	},

}))

const ErreplusView = () => {
	const classes = useStyles()

	const { datoCmsPageErreplusRoberto } = useStaticQuery(graphql`
		query MyQuery {
  datoCmsPageErreplusRoberto {
    heroTitle
    heroImage {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
    }
    team {
      teamName
      teamDescription
      teamImage {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
    title
    erreplusTitle
    erreplusImage {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
    }
    erreplusDescription
  }
}
	`)

	const { heroTitle, heroImage, erreplusTitle, erreplusDescription, erreplusImage, team, title } = datoCmsPageErreplusRoberto || {}

	const robertoData = team && team[0]
	const { teamName, teamDescription, teamImage } = robertoData

	return (
		<React.Fragment>
			<Box className={classes.heroTitle}>
				<Hero full image={heroImage} title={heroTitle} />
			</Box>
			<Container maxWidth="lg">
				<RobertoView title={title} subtitle={teamName} description={teamDescription} image={teamImage} />
				<GreyTextView title={erreplusTitle} description={erreplusDescription} image={erreplusImage} />
			</Container>
		</React.Fragment>
	)
}

export default ErreplusView;