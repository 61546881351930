import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import cx from 'classnames'

import { makeStyles } from "@material-ui/core/styles"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Title from "../../components/title"

const useStyles = makeStyles(theme => ({
	wrapper: {
		height: '100%',
		[theme.breakpoints.down('sm')]: {
			textAlign: "center"
		}
	},
	teamName: {
		fontSize: "1.6rem",
		marginBottom: theme.spacing(3),
		[theme.breakpoints.up('md')]: {
			fontSize: "2.8rem",
		}
	},
	details: {
		marginTop: theme.spacing(3),
		padding: theme.spacing(7),
		backgroundColor: theme.palette.common.white,
		[theme.breakpoints.down('sm')]: {
			marginTop: theme.spacing(2),
		}
	},
	featuredWrapper: {
		marginTop: theme.spacing(12),
		marginBottom: theme.spacing(16),
		[theme.breakpoints.down('sm')]: {
			marginBottom: theme.spacing(0),
		}
	},
	featuredDetails: {
		padding: theme.spacing(12),
		paddingLeft: theme.spacing(14),
		[theme.breakpoints.down('md')]: {
			padding: theme.spacing(6),
		},
	},
	featuredImage: {
		top: theme.spacing(4),
		width: `calc(100% + ${theme.spacing(6)}px)`,
		position: "relative",
		[theme.breakpoints.down('md')]: {
			marginTop: theme.spacing(3),
			top: "unset",
			width: "unset",
			maxWidth: theme.spacing(50)
		}
	},
	featuredContainer: {
		position: 'relative',
		marginTop: theme.spacing(5),
		[theme.breakpoints.down('md')]: {
			marginTop: "unset"
		}
	},
	sign: {
		'& img': {
			width: '50%',
			maxWidth: '200px',
		}
	},
}))


const RobertoView = ({ title, subtitle, description, image }) => {
	const classes = useStyles()

	return (
		<Grid item xs={12} className={classes.featuredWrapper}>
			<Grid container item>
				<Grid item md={6} xs={12} className={classes.featuredContainer}>
					<Title>{title}</Title>
					<GatsbyImage
						alt="Roberto"
						image={getImage(image)}
						className={classes.featuredImage}
					/>
				</Grid>
				<Grid className={classes.sign} container item md={6} xs={12}>
					<Box p={10} className={cx(classes.details, classes.featuredDetails)}>
						<Title className={classes.teamName}>{subtitle}</Title>
						<Typography dangerouslySetInnerHTML={{
							__html: description
						}} />
					</Box>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default RobertoView
