import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from "@material-ui/core/styles"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Title from "../../components/title"

const useStyles = makeStyles(theme => ({
	gridContainer: {
		justifyContent: 'space-between',
		alignContent: 'center',
		justifyContent: 'center',
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
		marginBottom: theme.spacing(7),
		backgroundColor: '#E1E1E1',
		[theme.breakpoints.up('md')]: {
			paddingTop: theme.spacing(6),
			paddingBottom: theme.spacing(6),
			paddingLeft: theme.spacing(10),
			paddingRight: theme.spacing(10),
		}
	},
	title: {
		fontSize: "3rem",
		marginBottom: theme.spacing(3),
		[theme.breakpoints.up('sm')]: {
			fontSize: "3.8rem",
		}
	},
	imageContainer: {
		width: '100%',
		height: '300px',
		position: 'relative',
		[theme.breakpoints.up('md')]: {
			height: 'auto'
		}
	},
	image: {
		position: 'absolute',
		top: '50%',
		transform: 'translateY(-50%)',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			margin: theme.spacing(3)
		}
	}
}))


const GreyTextView = ({ title, description, image }) => {
	const classes = useStyles()

	return (
		<Grid container className={classes.gridContainer}>
			<Grid item sx={12} md={6}>
				<Title className={classes.title}>
					{title}
				</Title>
				<Typography>
					{description}
				</Typography>
			</Grid>
			<Grid item sx={12} md={6} className={classes.imageContainer}>
				<GatsbyImage alt="Erreplus" image={getImage(image)} className={classes.image} />
			</Grid>
		</Grid>
	)
}

export default GreyTextView
