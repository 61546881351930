import React from 'react';

import Layout from '../../components/layout';
import ErreplusView from '../../views/erreplusViev'

const ErreplusPage = () => (
	<Layout>
		<ErreplusView />
	</Layout>
)

export default ErreplusPage;